import React, { useEffect, useState } from 'react'
import { SurveyAnswer, SurveyBtn, SurveyContent, SurveyForm, SurveyQuestion, SurveyInput } from './SurveyElements'
import { v4 as uuidv4 } from 'uuid'
import { db, collection, addDoc } from '../../firebase'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const SurveyValidate = ({ surveyData }) => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [location, setLocation] = useState('')
    const [interested, setInterested] = useState('')
    const navigate = useNavigate()
    const {t, i18n} = useTranslation()
    const [alignment, setAlignement] = useState(false)

    useEffect(() => {
        setAlignement(i18n.language === 'ar')
      }, [i18n.language])

    const handleSubmit = async (e) => {
        e.preventDefault()

        const submissionData = {
            id: uuidv4(),
            name,
            email,
            location,
            interested,
            surveyAnswers: surveyData
        }

        try {
            await addDoc(collection(db, "survey_submissions"), submissionData)
            alert('Survey submitted successfully!')
            navigate('/')
        } catch (error) {
            console.error("Error submitting survey: ", error);
            alert('Failed to submit survey: ' + error.message);
        }

        console.log('Submission Data:', submissionData)
    }

  return (
    <SurveyContent align={alignment}>
        <SurveyForm onSubmit={handleSubmit}>
            <div className="form-group">
                <SurveyQuestion htmlFor="name">{t("survey_validation.head1")}</SurveyQuestion><br/>
                <SurveyInput
                    type="text"
                    className="form-control"
                    placeholder={t("survey_validation.head1")}
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    align={alignment}
                />
            </div>
            <div className="form-group">
                <SurveyQuestion htmlFor="email">{t("survey_validation.head2")}</SurveyQuestion><br/>
                <SurveyInput
                    type="email"
                    className="form-control"
                    placeholder={t("survey_validation.head2")}
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    align={alignment}
                />
            </div>
            <div className="form-group">
                <SurveyQuestion htmlFor="location">{t("survey_validation.head3")}</SurveyQuestion><br/>
                <SurveyInput
                    type="text"
                    className="form-control"
                    placeholder={t("survey_validation.label3")}
                    id="location"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    required
                    align={alignment}
                />
            </div>
            <div className="form-group">
                <SurveyQuestion>{t("survey_validation.head4")}</SurveyQuestion>
                <SurveyAnswer className="form-check" align={alignment}>
                    <input
                        className="form-check-input"
                        type="radio"
                        name="interested"
                        id="interestedYes"
                        value="Yes"
                        checked={interested === "Yes"}
                        onChange={(e) => setInterested(e.target.value)}
                        required
                    />
                    <label className="form-check-label" htmlFor="interestedYes">
                        {t("survey_validation.value1")}
                    </label>
                </SurveyAnswer>
                <SurveyAnswer className="form-check" align={alignment}>
                    <input
                        className="form-check-input"
                        type="radio"
                        name="interested"
                        id="interestedNo"
                        value="No"
                        checked={interested === "No"}
                        onChange={(e) => setInterested(e.target.value)}
                        required
                    />
                    <label className="form-check-label" htmlFor="interestedNo">
                        {t("survey_validation.value2")}
                    </label>
                </SurveyAnswer>
            </div>
            <SurveyBtn 
                type="submit"
                primary = {true}
                dark = {false}
                big = {false}>
                    {t("survey_validation.link")}
            </SurveyBtn>
        </SurveyForm>
    </SurveyContent>
  )
}

export default SurveyValidate
